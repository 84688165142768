import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion';
import ReactVideo from "../../../video-player/ReactVideo";

import versaRfidAssignedHowManagerCreds from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-add-man-cred.jpg";
import versaRfidAssignedUserCreds from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-user-cred.jpg";
import versaRfidExpressManCred from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-express-man-cred.jpg";
import versaRfidSetupLock from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-set-up-lock.jpg";
import versaRfidOperateManKey from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-oper-man-key.jpg";
import versaRfidOperateProgrammingKey from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-oper-prog-key.jpg";
import versaRfidOperAssRFID from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-oper-ass-rfid-cred.jpg";
import versaRfidRemUserCred from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-remove-user-cred.jpg";
import versaRfidRepDelManCred from "../../../../images/support/posters/versa-ba/rfid/assigned/rfid-assigned-rep-del-man-cred.jpg";

import versaRfidSharedSetupLock from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-setup-use-lock.jpg';
import versaRfidSharedAddManCred from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-add-man-cred.jpg';
import versaRfidSharedExpManCred from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-exp-reg-man-cred.jpg';
import versaRfidSharedOperManRfidCred from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-oper-man-rfid-cred.jpg';
import versaRfidSharedOperManKey from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-how-oper-man-key.jpg';
import versaRfidSharedOperProgKey from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-how-oper-prog-key.jpg';
import versaRfidSharedOperUserRfidCred from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-how-oper-user-rfid-cred.jpg';
import versaRfidSharedRepDelManCred from '../../../../images/support/posters/versa-ba/rfid/shared/rfid-shared-how-rep-del-man-cred.jpg';

import versaKeySharedProgNewLock from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-prog-new-lock.jpg";
import versaKeySharedExpRegManKeys from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-exp-reg-man-keys.jpg";
import versaKeySharedAddManKeys from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-add-man-keys.jpg";
import versaKeySharedRepManKeys from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-rep-man-keys.jpg";
import versaKeySharedOperUserCode from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-oper-user-code.jpg";
import versaKeySharedOperManKey from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-oper-man-key.jpg";
import versaKeySharedOperProgKey from "../../../../images/support/posters/versa-ba/keypad/shared/key-shared-oper-prog-key.jpg";


import versaKeyAssignedNewLock from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-prog-new-lock.jpg";
import versaKeyAssignedAddManKeys from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-add-man-keys.jpg";
import versaKeyAssignedRepManKeys from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-rep-man-keys.jpg";
import versaKeyAssignedExpManKeys from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-exp-man-key.jpg";
import versaKeyAssignedAssUserCodes from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-user-codes.jpg";
import versaKeyAssignedExpRegCredKeys from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-exp-reg-creds-keys.jpg";
import versaKeyAssignedRemUserCreds from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-rem-user-creds.jpg";
import versaKeyAssignedCodeWithCode from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-rep-code-with-code.jpg";
import versaKeyAssignedOperWithCode from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-oper-with-user-code.jpg";
import versaKeyAssignedOperWithManKey from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-oper-man-key.jpg";
import versaKeyAssignedOperProgKey from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-oper-prog-key.jpg";
import versaKeyAssignedLockFunctionality from "../../../../images/support/posters/versa-ba/keypad/assigned/key-assigned-set-lock-functionality.jpg";

let datasheetsKeypad = [
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Horizontal Standard Body | Shared or Assigned | Knob/ADA Handle on Right",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4hfhoDMsNkwWZk1GTE1f1P/5cb395f637ba4d2c1c38222b5c121a23/DS-D6VK-X-3-0XR-DEN.pdf",
      "fileName": "DS-D6VK-X-3-0XR-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Vertical Body | Shared or Assigned | Knob/ADA Handle on Top",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6QFQwXtgLaS4plpcTtIEzq/12c741d3625dc39a4e83b9615f6ec11d/DS-D6VK-X-3-0XT-DEN.pdf",
      "fileName": "DS-D6VK-X-3-0XT-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Vertical Body | Shared or Assigned | Knob/ADA Handle on Bottom",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7eUUUPsr9XK0YMYWYgHz3B/4001201f49aaada8bc520dff73cba884/DS-D6VK-X-3-0XB-DEN.pdf",
      "fileName": "DS-D6VK-X-3-0XB-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Horizontal Standard Body | Shared or Assigned | Knob/ADA Handle on Left",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/ZklZ2E09CLCnQNhLPbzK2/580f70ea4602e97fa23f4103c183ed46/DS-D6VK-X-3-0XL-DEN.pdf",
      "fileName": "DS-D6VK-X-3-0XL-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Vertical Mini | Shared or Assigned | Knob/ADA Handle on Bottom",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/PhrP67MoZIZ7fxTWqPQu8/67f5c114831d3404a9735345fde22909/DS-D6VK-X-2-0XB-DEN.pdf",
      "fileName": "DS-D6VK-X-2-0XB-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Horizontal Mini | Shared or Assigned | Knob/ADA Handle on Left",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/16YqOZGk2n3CSJ5nEC7mLy/5edd380fef3912716c683ac73a155743/DS-D6VK-X-2-0XL-DEN.pdf",
      "fileName": "DS-D6VK-X-2-0XL-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Surface Mount | Horizontal Mini | Shared or Assigned | Knob/ADA Handle on Right",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6L7qSZwpTJW8AxH8Saynxl/a2d1334e28a62b8ffa82f7a591653cca/DS-D6VK-X-2-0XR-DEN.pdf",
      "fileName": "DS-D6VK-X-2-0XR-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Vertical Mini | Shared or Assigned | Knob/ADA Handle on Bottom",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/qh0I4dP5TEYMNnJxbi36R/7cf9d3c03501529182c9fa8cef55db38/DS-D6VK-X-2-3XB-DEN.pdf",
      "fileName": "DS-D6VK-X-2-3XB-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Horizontal Mini | Shared or Assigned | Knob/ADA Handle on Left",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2XOShPfEwb9ud1CjUBmvTl/16ad8e9159f2866a3bcb5ddc05e68d29/DS-D6VK-X-2-3XL-DEN.pdf",
      "fileName": "DS-D6VK-X-2-3XL-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Horizontal Mini | Shared or Assigned | Knob/ADA Handle on Right",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6vFcGI60NAKHWKCTDfyQXI/5de3ff8e034558adff8bf750bb8f83ae/DS-D6VK-X-2-3XR-DEN.pdf",
      "fileName": "DS-D6VK-X-2-3XR-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Vertical | Shared or Assigned | Knob/ADA Handle on Top",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7BdR3N6oYhpN3hZZzdkKb1/1846a3761604ca65b8e7d7885dde14fd/DS-D6VK-X-2-3XT-DEN.pdf",
      "fileName": "DS-D6VK-X-2-3XT-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Vertical | Shared or Assigned | Knob/ADA Handle on Bottom",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/14r7hszTABJjNs6lbiLQRK/46dfd5b05be487d1ba0989dd97cca413/DS-D6VK-X-3-3XB-DEN.pdf",
      "fileName": "DS-D6VK-X-3-3XB-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Horizontal | Shared or Assigned | Knob/ADA Handle on Left",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2UjNeS8GCPxCDr4VpOpSkj/c6bab05cdec85b4ec2e51c3526dce333/DS-D6VK-X-3-3XL-DEN.pdf",
      "fileName": "DS-D6VK-X-3-3XL-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Keypad | Recess Mount | Vertical | Shared or Assigned | Knob/ADA Handle on Top",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7I8xkJTXpHSSLtEWWhaU9e/9ceee3d0566e7a974631e6c9e2dccf1f/DS-D6VK-X-3-3XT-DEN.pdf",
      "fileName": "DS-D6VK-X-3-3XT-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Versa | Basic & Advanced | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3hjls6jz174E12kcw5w4h9/c0c8210a6edf090db29acf4dbc54d84c/IG-D6VM-KR-DEN.pdf",
      "fileName": "IG-D6VM-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/15qHdQmK5PasiPgK7iHHt7/21ec8031e7665e9a08509bad6ce0272e/IG-D6VS-KR-DEN.pdf",
      "fileName": "IG-D6VS-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Versa | Basic & Advanced | Keypad | Mini & Standard",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1VQ3KeUTU6nztQ9NPxKVNf/12e61821cd94e43751a397d0cd01a21b/PG-D6VKX-DEN.pdf",
      "fileName": "PG-D6VKX-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

let datasheetsRFID = [
  {
    "title": "Versa | Basic & Advanced | RFID | Surface Mount | Narrow | Shared or Assigned | Knob/ADA Handle on Bottom, Left, or Right",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5IWFWYAxULW1QAHgTF0aft/15d727c41624fb6d8d3fd3e7371301ab/DS-D6VR-X-3-0XN-DEN.pdf",
      "fileName": "DS-D6VR-X-3-0XN-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | RFID | Surface Mount | Vertical | Shared or Assigned | Knob/ADA Handle on Top",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2ArqkjwNoLZzSdSD49YWAH/aa7fd6b9b9b034713d58d2da0c2630b8/DS-D6VR-X-3-0XT-DEN.pdf",
      "fileName": "DS-D6VR-X-3-0XT-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | RFID | Recess Mount | Narrow | Shared or Assigned | Knob/ADA Handle on Bottom, Left, or Right",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7zd0RYEawIby7sEJoMjigi/2c1a06c9220945ee4a217c258d8596aa/DS-D6VR-X-3-3XN-DEN.pdf",
      "fileName": "DS-D6VR-X-3-3XN-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | RFID | Recess Mount | Vertical | Shared or Assigned | Knob/ADA Handle on Top",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/59ML7XukEeg07OL2u7LoVl/03f15a4095b2ae13562928b020cdef28/DS-D6VR-X-3-3XT-DEN.pdf",
      "fileName": "DS-D6VR-X-3-3XT-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Versa | Basic & Advanced | Hybrid",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/592GMpyvJ9FHcPw1BRoeoi/a6da43fce049e8f7644a212d294d4769/IG-D6VH-BA-DEN.pdf",
      "fileName": "IG-D6VH-BA-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Mini ",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7FWARdsClmylQChI7sIeA/be544b920c9f0fa30bbef5dbe3d43125/IG-D6VM-KR-DEN.pdf",
      "fileName": "IG-D6VM-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3tI8RtEOT3fXV3oNpFuH9Q/32d3cd2f93af2476a0ea02c433f4add9/IG-D6VS-KR-DEN.pdf",
      "fileName": "IG-D6VS-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Versa | Basic & Advanced | RFID | Mini/Standard/Hybrid | Hand Triggered Programming",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/76CyFuL7iLFs0TSLoDcbxX/81832bcf3217ef776b5c4c255c916d09/PG-D6VRX-DEN.pdf",
      "fileName": "PG-D6VRX-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Basic & Advanced | RFID | Mini/Standard/Hybrid | Key Triggered Programming",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3HXVJEQw7SwuGh1PPLKZE6/ae569a67c11c270ff4f61b5a59ed1a84/PG-D6VRX-KEYOPR-DEN.pdf",
      "fileName": "PG-D6VRX-KEYOPR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  useEffect(() => {
    if (document.location.hash === '#rfid') {
      setToggleState(2);
    }
  }, [])

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Versa <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Versa <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/keypad.png'}
                  loading={'lazy'}
                  width={388}
                  height={818}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Versa Electronic Lock"
              />
            </div>
            <div className="lists">
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          datasheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="How To Videos">
                    <Accordion clsName={'next-support'}>
                      <div label={'Assigned Use'}>
                        <div className={'aspire-how-to-vids'}>


                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/tojy00a0DEmzBNlQBlaMZg/icbvyHA_N0O44e_UFtps_w/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedAddManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/MUzUZi7E10O4yhLT0X0LpA/UYs41RwE1E6Ei5wMk5Y-CA/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-02_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedRepManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/_Z91lU7p4EGONtlMoJrHAA/pqUvLJ5QWUSSUXGVwWYoNg/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-03_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedExpManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/kJ5Nl9EzE0OBsVXNEVt0lg/5mWmVW9XHk2fey3gwNv4LQ/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-02_How%20to%20Express%20Register%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedAssUserCodes}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/0PMHMOtokk21zCU_yokNiA/Dn0Cu5yRFkC5c8Qy1IPJfg/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-04_How%20to%20Assign%20User%20Codes.mp4'}
                            />
                            <p>How to assign user codes</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedExpRegCredKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/itNTWJmUUU6VjCyfirVNMg/NeR65H7_fUWl3PnuAOxBGg/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-05_How%20to%20Express%20Register%20User%20Credentials%20and%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register user credentials and manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedRemUserCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ULzYiCyEtkCUetDLxpJVQw/LV2_NJPuFkq_Lf2wfNlhFw/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-06_How%20to%20Remove%20User%20Credentials.mp4'}
                            />
                            <p>How to remove user credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedCodeWithCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/v4h2oExRqk2_CcuSjQwbnQ/0pXp3n_nAEmAY77GhktWCQ/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Assigned%20Use-07_How%20to%20Replace%20an%20Assigned%20User%20Code%20with%20a%20New%20User%20Code.mp4'}
                            />
                            <p>How to replace an assigned user code with a new user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedOperWithCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/4flag5x2zk-LKzP3VAAplQ/W8l0Q1fKOUm_7Tt2qrYXng/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_AssignedUse-08_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedOperWithManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/tT202CK3SUu5reLq9UZ3fQ/hkww52SV30aAuuQWeqnl5Q/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_AssignedUse-09_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>

                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedOperProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/cwsICSy6BU2RK2ep36LgFQ/krQmv8mTrEmPBU0AsActJA/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_AssignedUse-10_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedLockFunctionality}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ap76a1ovP0KhwNGTif_aqw/-dWyrxb2jU-GGSFqFMK9MQ/Widescreen720p/6G%20Versa%20Keypad%20Basic%20and%20Advanced%20Change%20Functionality.mp4'}
                            />
                            <p>How to set lock functionality</p>
                          </div>

                        </div>
                      </div>
                      <div label={'Shared Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedProgNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/n1FuNBvhUku9adlJokjyCg/ucoKXnrWU0ilQJMLsWjEsw/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Shared%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedExpRegManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/aTnpx7m-9kiLuNBqAZPugg/Nfwg9VGztEq12zoDEjuFpA/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Shared%20Use-02_How%20to%20Express%20Register%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedAddManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/CHkL5GfaE0u-JX_Aj9t4qg/gAKGaspkSUGtp5ynU5cTXw/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Shared%20Use-03_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedRepManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/fjigD9zh1kCfLiUzpXXteA/jw_sC51MlUe8J88wx-Kqrw/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_Shared%20Use-04_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedOperUserCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/kCUNA6F0lUWVi1o_PgiSkQ/6vzqdqR__EihEPdyqMS7aQ/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_SharedUse-05_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedOperManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/mfqSgAe-L0ygGYEEpOmmjg/XFBZDqOysEOVx7fxcrKeyQ/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_SharedUse-06_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeySharedOperProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/c2LaJw2u7EC91OGPXg3BGw/V-pq6nSQ-ECMwnQe4a2PsA/Widescreen720p/6G%20Versa%20Keypad_BasicAdv_SharedUse-07_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaKeyAssignedLockFunctionality}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ap76a1ovP0KhwNGTif_aqw/jEX4aI4J_0qaBZK75ZLIqQ/Widescreen720p/6G%20Versa%20Keypad%20Basic%20and%20Advanced%20Change%20Functionality.mp4'}
                            />
                            <p>How to set lock functionality</p>
                          </div>
                        </div>
                      </div>
                    </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/rfid.png'}
                  loading={'lazy'}
                  width={388}
                  height={818}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Versa Electronic Lock"
              />
            </div>
            <div className="lists">
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          datasheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="How To Videos">
                    <Accordion clsName={'next-support'}>
                      <div label={'Assigned Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={versaRfidAssignedHowManagerCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/BjgQ5Fh6ZkaPW8AucP5JHA/ngnlbdNg5kG6BBllTgJXYw/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Assign%20Manager%20Credentials.mp4'}
                            />
                            <p>How to add manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidAssignedUserCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/yE4EogVXAkO_VaGOefEB_w/r9C6dRAuQEq92M91w6sc6g/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Assign%20User%20by%20Manager%20Credentials.mp4'}
                            />
                            <p>How to assign user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidExpressManCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ETe27setI02MNkMG9qCymg/7G3OaKjCuUat3HMbn-jmYg/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Express%20Register%20Manager%20Credentials.mp4'}
                            />
                            <p>How to express register manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSetupLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ksPb7RZoa0-4dk5-45X3VQ/2x03mjGRLUWA7Ey0InpPqA/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Inital%20Set%20Up.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidOperateManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/YNx7z5_HdEay2i9JMO9tOA/9otOnG5KQk-FyzTPE5Xc9A/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Operate%20with%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidOperateProgrammingKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/5ZUglxaCHkO2zzo0qofPkQ/42MuI9d55UiG9igeuEAffg/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Operate%20with%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidOperAssRFID}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/63ZtSIvrEUOGkPvW_LyTlg/g14uBWUe_Ua4MLDI9iPOtQ/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Operate%20with%20User%20Card.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidRemUserCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/e2sPWcCfwEeu_pKUWPSTZw/6vrSZC2oZUmNQlRVjkyxUw/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Remove%20user%20credentials.mp4'}
                            />
                            <p>How to remove user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidRepDelManCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/Ci0-zGdqF06eoxn_Wz_-CQ/wqCxuVhQK0ynNDWUM0Dsgw/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Assigned%20Use%20-%20Replace%20or%20Delete%20Manager%20Credentials.mp4'}
                            />
                            <p>How to replace manager keys/RFID credentials</p>
                          </div>
                        </div>
                      </div>
                      <div label={'Shared Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedSetupLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/85ZKZtCsFUqeByUQzlP9KQ/P_dU4W8tp0SV5Vrg8OZXIA/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Inital%20Set%20Up.mp4'}
                            />
                            <p>How to set up the lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedAddManCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/v17UYy33gEW6kkVLeOfAcQ/xVHIOTSi-06G-1KjZamfQQ/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Assign%20Manager%20Credentials.mp4'}
                            />
                            <p>How to add manager credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedExpManCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ns1L6vlJK0ql_7vpIbRHUw/tnRB4-uO202pY5rg6UeKYA/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Express%20Register%20Manager%20Credentials.mp4'}
                            />
                            <p>How to express register manager credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedOperManRfidCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/C8xyr8mQAUiLmkCs9KS9XQ/G66Wb4orX0eMDztkfg8VvA/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20Manager%20Card.mp4'}
                            />
                            <p>How to operate with a manager RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedOperManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/kCYTkDSSSEGnJ2iMFCpygw/WKVI0p3lgUWJymI18hSLgQ/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedOperProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/c93JJq_l5U-i8kGl4G5cxA/cXJZj_BCB0SDkHlIXPzNyQ/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={versaRfidSharedOperUserRfidCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/fRTcrgRrxUGKrdO3OV9AeA/oVg22wbmjUi8GRndWnwMrw/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20User%20Card.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>

                          <div>
                            <ReactVideo
                                poster={versaRfidSharedRepDelManCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/2L9ZlEahdk2DRoxS4_eG-w/IBu1Oplzr0i6SNOaLaTXQQ/Widescreen720p/6G%20Versa%20RFID_BasicAdv_Shared%20Use%20-%20Replace%20or%20Delete%20Manager%20Credentials.mp4'}
                            />
                            <p>How to replace or delete manager credentials</p>
                          </div>

                        </div>
                      </div>
                    </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;